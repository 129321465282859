@import '../../style/variable.scss';
.listingpanel {
    display: grid;
    width: 100%;
    gap: 10px;
    // padding: 20px 0;

    .category-card {
        img {
            width: 100%;
            border: 1px solid #ddd;
            padding: 6px;
            height: 150px;
            object-fit: cover;
        }
    }

    h3 {
        font-size: 16px !important;
        padding: 5px 0;
        font-weight: normal !important;
    }
    .listview {
        display: flex;
        width: 100%;
        // margin-bottom: 50px;
        padding-bottom: 10px;
        border-bottom: solid 1px #dbdbdb;

        img {
            height: 300px;
        }
        @include devices(tablet) {
            flex-wrap: wrap;
        }

        &:last-child {
            border-bottom: none;
        }
    }
    .limbo-slider {
        // width: 30%;
        @include devices(tablet) {
            width: 100%;
        }
    }
}
.listingwrap {
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 30px;
    @include devices(tablet) {
        padding: 20px;
    }
    h2 {
        margin: 20px 0;
        padding: 0;
    }
    .sortrow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 5px 0;
        align-items: center;

        h5 {
            color: $blue;
            text-transform: uppercase;
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
        .sortblock {
            display: flex;
            align-items: center;
            select {
                width: 230px;
                height: 50px;
                padding: 0 10px;
            }
        }
    }

    .ads {
        margin-bottom: 0px;
    }
}

.clear-filters {
    padding: 1rem !important;
    background-color: unset;
    font-size: 10px;
}
.limboListing {
    display: flex;
    gap: 20px;
    @include devices(tablet) {
        flex-wrap: wrap;
    }
}
.filterleft {
    width: 280px;
    @include devices(tablet) {
        width: 100%;
    }
}
