.notification-page {

    .chatroom-container {
        font-size: 12px;
        color: black;
        font-weight: 600;
        margin: 32px;
        border-radius: 8px;

        .chat-title {
            font-size: 20px;
            font-weight: 500;
            padding: 8px;
            border-bottom: 2px solid grey;
        }

        .chat-content {
            &:hover {
                cursor: pointer;
            }

            // border-bottom: 1px solid grey;
            min-width: 80px;
            padding: 16px;
            padding-left: 32px;
            // margin-bottom: 8px;

            .sender {
                font-size: 12px;
                color: grey;
                font-weight: 600;
            }

            .message {
                font-size: 14px;
            }

            border-bottom: 1px solid #d2d2d2;
        }

        .read {
            background-color: #ffffff;
        }

        .unread {
            background-color: #fafafa;
        }
    }

    .limbo-notification {
        font-size: 12px;
        color: black;
        font-weight: 600;
        margin: 32px;
        border-radius: 8px;
        box-shadow: 0px 0px 8px 1px rgb(171, 160, 160);


        .limbo-title {
            font-size: 20px;
            font-weight: 500;
            padding: 8px;
            border-bottom: 2px solid grey;
        }

        .limbo-content {
            padding: 8px;
            border-bottom: 2px solid grey;

            .limbo-details {
                &:hover {
                    cursor: pointer;
                }

                .limbo-line {
                    font-size: 18px;
                    font-weight: 400;
                    width: 100%;

                    .limbo-name {
                        font-size: 20px;
                    }
                }

                .open-limbo {
                    font-size: 12px;
                    font-style: italic;
                }
            }
        }
    }
}

.notification-page {
    .chatroom-container {
        font-size: 12px;
        color: black;
        font-weight: 600;
        // min-width: 300px;
        // max-height: 450px;
        // overflow-y: scroll;
        margin: 1rem;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid #d2d2d2;

        .chat-page-title {
            font-size: 18px;
            font-weight: 500;
            padding: 16px;
            border-bottom: 1px solid #d2d2d2;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .notification-number {
                font-size: 15px;
                font-weight: 300;

            }
        }

        .chat-content {
            // border-top: 1px solid green;
            min-width: 80px;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                cursor: pointer;
            }

            .chat-img-title {
                max-height: 28px;

                .chat-img {
                    // min-height: 30px;
                    // min-width: 30px;
                }

                .chat-email {
                    font-size: 14px;
                }

                span {

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // grid-template-columns: 1fr 1fr 1fr;

                    @media screen and (max-width: 900px) {
                        // grid-template-columns: 1fr 2fr 1fr;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .chat-message {
                margin-left: 2.4rem;
                font-weight: 400;
                font-size: 14px;
                // padding-top: 0.35rem;


            }

            .chat-timestamp {
                margin-left: 2.4rem;
                font-weight: 300;
                font-size: 11px;
                padding-top: 0.35rem;

            }

        }
    }
}