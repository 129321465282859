.pageNotFound {
    display: flex;
    flex-direction: column;
    font-size: 4rem;
    padding: 4rem;
    font-weight: 800;
    color: lightblue;
    &__wrapper {
        img{
            height: 70vh;
        }
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and ( max-width:1024px) {
    .pageNotFound {
            &__wrapper {
            flex-direction: column;
        }
    }
}