.dashboard-container {
    display: grid;
    grid-template-columns: 366px 1fr;

    .left-section {
        width: 366px;
        height: 100%;
        flex-shrink: 0;
        background: #fff;
        box-shadow: 0px 20px 30px 0px rgba(209, 220, 226, 0.45);
    }

    .right-section {
        .dashboard-header {
            display: flex;
            padding: 18px 20px 14px;
            align-items: center;
            border-bottom: 1px solid #ddd;
            gap: 20px;

            &__icon {
                padding: 0;
                color: #000000;
            }

            &__title {
                font-weight: 600;
                font-size: 22px;
                color: #000;
                padding: 0;

                @media screen and (max-width: 580px) {
                    font-size: 16px;
                }
            }
        }

        .dashboard-content {
            padding: 10px 0;

            @media screen and (max-width: 720px) {
                padding: 0;
            }

            .btn-row {
                display: flex;
                justify-content: end;
                margin-bottom: 10px;
                margin-top: -65px;
                align-items: center;
                margin-right: 2rem;

                @media screen and (max-width: 920px) {
                    margin-top: 10px;
                    padding-right: 10px;
                    gap: 5px;
                }
            }
        }
    }
}

.dashboard-limbo-listing-section {
    .limbo-cards-section {
        .limbo-cards {
            .card {
                img {
                    height: 250px;
                    object-fit: cover;
                }
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .dashboard-container {
        grid-template-columns: 280px 1fr;

        .left-section {
            width: 280px;
            height: 1238px;
        }
    }
}

@media screen and (max-width: 980px) {
    .table-responsive {
        overflow-x: auto;
    }

    .right-section {
        overflow: auto;
    }
}

@media screen and (max-width: 767px) {
    .dashboard-container {
        grid-template-columns: none;
        display: flex;

        .left-section {
            width: 80px;

            .container {
                padding: 0 0;
            }
        }

        .right-section {
            display: flex;
            width: calc(100% - 80px);
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 580px) {
    .dashboard-container {
        .right-section {
            .dashboard-content {
                .btn-row {
                    a.btn.btn-orange {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.delete__icon {
    &:hover {
        cursor: pointer;
    }
}
