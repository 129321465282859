@import '../../style/mixins.scss';
@import '../../style/variable.scss';

.limbo-closed {
    color: #f00;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    border: 1px solid #b6b6b6;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
}
