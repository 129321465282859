@import '../../style/variable.scss';
@import '../../style/mixins.scss';


.sort-grid-section {
    display: flex;
    gap: 30px;
    .sortby {
        text-transform: uppercase;
        ul {
            @include align-center;
            gap: 14px;
            li {
                padding-right: 0;
                @include align-center;
                gap: 20px;
                &::after {
                    display: none;
                }
                &.list-grid {
                    display: flex;
                    gap: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .dashboard-shop-Category-section {
        .limbo-heading {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .sort-grid-section {
        gap: 10px;
        font-size: 12px;
        margin-top: 20px;
        width: 100%;
        justify-content: space-between;
        .sortby {
            width: 100%;
            ul {
                gap: 5px;
                width: 100%;
                li {
                    width: auto;
                    @include align-center;
                }
            }
            &.m-hide {
                align-self: flex-end;
                width: auto !important;
                ul {
                    li {
                        select {
                            width: 90px !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .sort-grid-section {
        .sortby {
            ul {
                li {
                    width: 100%;
                }
            }
        }
    }
}
