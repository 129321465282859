@import '../../style/variable.scss';
.filterblock {
    border: 1px solid $light-gray;
    padding: 20px 15px;
    width: 280px;
    margin-bottom: 15px;
    box-sizing: border-box;
    @include devices(tablet) {
        width: 100%;
    }
    h5 {
        color: $blue;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }
    p {
        color: $grey;
        font-size: 14px;
    }
    input[type='text'] {
        height: 40px;
    }
    .pricinput {
        display: flex;
        gap: 20px;
        margin-bottom: 15px;
    }
    .btn-blue {
        background: $blue;
        border: 0;
        height: 42px;
        color: white;
        width: 100%;
    }
    .checkboxrow {
        display: flex;

        label {
            display: flex;
            align-items: center;
        }
    }

    .list-container {
        max-height: 300px;
        overflow: auto;
    }
}
