.create-limbo-container {
    width: 100%;
    height: 100%;
    background: #fff;

    .pageheader {
        padding: 14px 30px;
        h1 {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    .title {
        background-color: #b6e2c580;
        width: 100%;
        height: 100px;
        padding: 24px 0px;
        text-align: center;
    }

    .form-container {
        padding: 24px 0px;
        display: flex;
        align-items: center;
        max-width: 1400px;
        margin: 0 auto;
        flex-direction: column;
        padding: 0 30px;
        .form-title {
            text-align: left;
            margin: 1rem 0rem 2rem 0rem;

            h3 {
                font-size: 1rem;
            }
        }

        .limbo-form {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            max-width: 500px;
            .form-fields {
                display: flex;
                flex-direction: column;
                width: 100%;
                .product-images-holder {
                    display: flex;
                    justify-content: space-between;
                }

                .location-markings {
                    display: flex;
                    justify-content: space-between;
                }
            }

            label {
                color: #424242;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 700 !important;
                line-height: 30px; /* 214.286% */
                text-transform: capitalize;
            }

            input,
            select {
                height: 44px;
                border: 1px solid #ccc;
                background: #fff;

                &:focus {
                    outline: none;
                }
            }
            input[type='file'] {
                border: 0;
            }
            textarea {
                border: 1px solid #ccc;
                background: #fff;

                &:focus {
                    outline: none;
                }
            }

            input[type='checkbox' i] {
                height: unset;
            }

            input[type='number' i] {
                padding: 1rem;
            }

            input[type='range' i] {
                appearance: auto;
                cursor: default;
                color: -internal-light-dark(
                    rgb(16, 16, 16),
                    rgb(255, 255, 255)
                );
                padding: 0.8px !important;
                border: initial;
                margin: 2px;
                width: 528px;
                height: 1px;
            }
            .check-boxes-form-fields {
                display: flex;
                margin: 0;
                width: 100%;
            }
        }

        .button-group {
            #start {
                background-color: #ff9923;
                color: white;
            }

            #draft {
                background-color: #d3e9da;
                color: #6d6d6d;
            }
        }
    }
}
