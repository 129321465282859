@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Kaushan+Script&display=swap');

.carousel.slide {
    .carousel-inner {
        h1 {
            font-family: 'Kaushan Script', cursive;
            margin-bottom: 0;
            padding-bottom: 10px;
            font-size: 45px;
        }
        h2 {
            font-family: 'Kaushan Script', cursive;
            margin-bottom: 0;
            padding-bottom: 10px;
            font-weight: normal !important;
            font-size: 30px;
        }
    }
}

button {
    &.btn {
        padding: 0.84rem 2.14rem !important;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
    }
}

.btn-grey:not([disabled]):not(.disabled):active {
    color: #fff;
}
.btn-orange:not([disabled]):not(.disabled):active {
    color: #fff;
}

div:where(.swal2-icon) {
    .swal2-error {
        [class^='swal2-x-mark-line'] {
            [class$='right'] {
                right: -9px;
            }
            [class$='left'] {
                left: -15px;
            }
        }
    }
}

.listingpanel {
    margin-top: 15px;
    gap: 35px;
    h3 {
        font-size: 24px !important;
        padding: 10px 0 5px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700 !important;
    }
}

span {
    &.view-detail {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.category-card {
    h3 {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
}

.listingwrap {
    padding: 0 15px 40px;
    .sortrow {
        display: flex;
        justify-content: space-between;
        padding: 10px 0 10px;
        align-items: center;
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;
    }
}

.header-popover-notification {
    .chatroom-container {
        .chat-title {
            color: #000;
            font-size: 15px;
            font-weight: 600;
            border-bottom: 1px solid #ffffff !important;
            padding: 25px 20px 20px;
            .notification-number {
                font-size: 12px;
                font-weight: 400;
                color: #8f8f8f;
            }
        }
        .chat-content {
            border-top: 1px solid #ddd;
            .chat-img-title {
                .chat-email {
                    font-size: 14px;
                    color: #000;
                    font-weight: 700;
                }
            }
            .chat-message {
                color: #525252;
            }
            .chat-timestamp {
                font-weight: 400;
                font-size: 12px;
                color: #8f8f8f;
            }
        }

        .show-all-notification-button {
            border-top: 1px solid #e5e5e5;
            font-size: 13px;
            font-weight: 500;
        }
    }
}

.navbar {
    .navbar-nav {
        a {
            font-weight: 400;
        }
    }
}

.limbos__subscription {
    .plan__active {
        background: #00b95f !important;
        color: #fff;
    }
}
.ads {
    padding: 0;
    img {
        padding: 20px;
    }
}

.chatroom-container {
    padding-right: 5px;
    &::-webkit-scrollbar {
        width: 7px;
        &-track {
            background: none;
        }
        &-thumb {
            background-color: #999;
            border-radius: 20px;
            position: relative;
            right: 5px;
        }
    }
}

div#popover-trigger-click-root-close {
    border: 1px solid #eaeaea;
    box-shadow: 0px 0px 8px #c2c2c2;
}

.time-detail {
    .chat-vendor {
        margin-bottom: 15px;
    }
    .limbo--details {
        h6 {
            text-transform: uppercase;
        }
    }
}

.sort-grid-section {
    gap: 10px;
    .sortby {
        ul {
            li {
                gap: 10px;
                text-transform: capitalize;
            }
        }
    }
}

span.list-created {
    display: flex;
    gap: 10px;
}

.limbo-panel {
    .flex-inline {
        grid-template-columns: 55% 45%;
        .label {
            position: relative;
            margin-right: 20px;
            &::before {
                position: absolute;
                content: ':';
                right: 0;
            }
        }
    }
}

.filterblock {
    padding: 10px 15px;
    margin-bottom: 10px;
}

.time-detail {
    .flex-inline {
        margin-bottom: 0px;
        padding: 8px 0;
        border-bottom: 1px solid #ebebeb;
    }
    .limbo--details {
        .limbo-item {
            border-bottom: none;
            padding-bottom: 20px;
            margin-bottom: 0px;
        }
    }
}

.limbo-details-section {
    .details-section {
        grid-template-columns: 45% 55%;
        .imgPanel {
            img {
                width: 100%;
            }
        }
    }
}
.limbo-created {
    time {
        color: #36afc9;
        padding-left: 8px;
    }
}

.priceFilter {
    &.filterblock {
        padding: 0;
        border: none;
    }
}

.category-limbo {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: 25px;
    .category-card {
        display: grid;
        width: 100%;
        gap: 15px;
        text-align: center;
        img {
            width: 100%;
            border: 1px solid #ddd;
            padding: 6px;
            height: 150px;
            object-fit: cover;
        }
    }
}

.panel-list {
    grid-template-columns: 1fr;
}

.create-limbo-container {
    .form-container {
        .limbo-form {
            margin-bottom: 40px !important;

            input[type='range' i] {
                max-width: 500px;
                width: 100%;
            }
            .form-fields {
                .location-markings {
                    margin-top: 20px;
                }
            }
        }
    }
}

.top-links {
    ul {
        li {
            &.btn-createlimbo {
                button {
                    border: none;
                    background: none;
                }
            }
        }
    }
}

button {
    &.btn-createlimbo {
        border: none;
        background: none;
    }
    span {
        font-weight: 600 !important;
    }
}

.create-limbo-container {
    .form-container {
        padding: 24px 30px;
        .image-picker {
            width: 100%;
        }
    }
}

.upload-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: baseline;
}

.time-detail {
    .time-details {
        gap: 15px;
        flex-direction: column;
        align-items: flex-start;
    }
}

.notification-page {
    .chatroom-container {
        .chat-content {
            .chat-img-title {
                max-height: unset;
            }
            &:nth-child(even) {
                background: #fff;
            }
        }
    }
}

.product,
.services {
    margin-top: 20px;
}

.current-offer-table-section {
    table.table {
        td {
            padding: 18px 10px;
        }
    }
}

.loginLeft {
    padding: 40px 30px !important;
}

.quantity {
    .change-offer-btn {
        button {
            border-radius: 0;
        }
    }
}
.adsrow {
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.admin__table__csv td .category__img img {
    padding: 5px !important;
}

.icon__wrapper {
    display: flex;
    gap: 15px;
}

/* media query*/

@media screen and (max-width: 1400px) {
    .dashboard-limbo-listing-section {
        .limbo-cards-section {
            .limbo-cards {
                .card {
                    height: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .limbo-panel {
        .time-details {
            align-items: flex-start;
            padding-bottom: 5px;
            flex-direction: column;
            gap: 10px;
        }
    }

    .report__wrapper {
        align-items: flex-start;
        margin: 20px 0;
        align-items: center;
        gap: 15px;
        .btn {
            &.btn-primary {
                margin: 0 0 0px;
            }
        }
    }

    .time-detail {
        .report__wrapper {
            flex-direction: row;
        }
    }
    .btn-report {
        font-size: 12px;
    }
}

@media screen and (max-width: 1200px) {
    header {
        .topbar {
            padding: 0 0px;
        }
        .top-links {
            padding: 0px 0;
        }
        .header-icons {
            .notification-dot {
                top: -11px !important;
            }
        }
    }

    .dashboard-limbo-listing-section {
        .limbo-cards-section {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .userview {
        padding: 30px 10px;
    }

    .listingwrap {
        .limboListing {
            .listingpanel {
                grid-template-columns: repeat(1, 1fr) !important;
                .listview {
                    flex-direction: column;
                    .carousel-inner {
                        width: 100% !important;
                    }
                }
                .limbo-panel {
                    margin: 50px 0px 0 0px;
                    padding-right: 0px;
                }
            }
        }
    }

    .services .listingwrap .limboListing .listingpanel {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .product .listingwrap .limboListing .listingpanel {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

@media screen and (max-width: 991px) {
    .limbo-list ul li {
        gap: 10px;
    }

    .listing-list ul li {
        gap: 10px;
    }

    header {
        .topbar {
            padding: 0 0px;
        }
        .top-links {
            padding: 0px 5px;
        }

        .header {
            .header-icons {
                min-width: auto;
                .notification-dot {
                    right: -6px;
                    height: 25px;
                    width: 15px;
                    font-size: 10px;
                    top: -7px;
                }
            }
        }
    }
    .time-detail {
        .btn-section {
            display: grid !important;
            gap: 15px;
            grid-template-columns: 1fr 1fr;
            justify-content: space-between;
            align-items: center;
        }
    }

    .category-limbo {
        grid-template-columns: repeat(3, 1fr);
    }

    .services {
        .listingpanel {
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }
    }

    .dashboard-container .right-section .dashboard-content p {
        height: auto;
        overflow: auto;
    }

    .createListing a.btn-orange {
        padding: 9px 18px;
    }
}

@media screen and (max-width: 767px) {
    header .navbar .navbar-nav {
        z-index: 9;
    }

    header {
        .topbar {
            position: absolute;
            width: 100%;
            right: 0;
            height: 60px;
            align-items: center;
        }
    }

    .top-links {
        ul {
            span {
                display: none;
            }
            li {
                &.btn-createlimbo {
                    right: 60px;
                    position: absolute;
                    button {
                        border: none;
                        background: none;
                    }
                }
            }
        }
    }

    nav {
        &.navbar {
            position: relative;
        }
        .top-links {
            background: none;
            width: 100%;
            justify-content: space-between;
            ul {
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 60px;
            }
        }
    }

    .limbos__subscription {
        div {
            font-size: 12px;
        }
        button {
            width: auto;

            font-size: 12px;
        }
    }

    .listingwrap {
        margin: 20px auto;
        padding: 0 20px;
    }

    footer {
        .footer {
            padding: 0px;
        }
    }

    .limbo-details-section {
        padding: 20px 30px 30px;
    }

    .time-detail {
        .btn-section {
            grid-template-columns: 1fr 1fr;
            .btn-report {
                text-align: right;
            }
        }
        .time-details {
            margin-bottom: 20px;
            padding: 20px 0 !important;
        }
    }

    .category-limbo {
        grid-template-columns: repeat(2, 1fr);
    }

    .services {
        .listingpanel {
            grid-template-columns: repeat(2, 1fr) !important;
            padding: 0px 0;
            .limbo-panel {
                margin: 0px;
            }
        }
    }

    .product .listingwrap .limboListing .listingpanel {
        grid-template-columns: repeat(2, 1fr) !important;
    }
    .services .listingwrap .limboListing .listingpanel {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .limbo-slider {
        margin-bottom: 40px;
    }

    .listview {
        margin-bottom: 10px !important;
        padding-bottom: 30px !important;
    }

    .listingwrap {
        .sortrow {
            margin-bottom: 15px;
            padding-bottom: 15px;
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .limbo-closed {
        font-size: 24px;
        transition: all ease 0.3s;
    }
    .current-offer-table {
        padding: 0 0px;
    }

    .listingpanel {
        .limbo-slider {
            margin-bottom: 0;
        }
    }

    .registerRight {
        justify-content: flex-start;
        padding-left: 0;
    }

    .registerLeft {
        width: 100%;
        margin: 0 auto 0px;
    }

    .icons svg {
        width: 15px;
    }
}

@media screen and (max-width: 680px) {
    span.chat-img-title-section {
        flex-direction: column;
        text-align: left;
        align-items: flex-start !important;
    }
}

@media screen and (max-width: 620px) {
    .btn-orange {
        font-size: 12px;
        padding: 12px 18px;
    }

    .owl-theme {
        .owl-dots {
            display: none;
        }
    }

    .navbar {
        .navbar-nav {
            gap: 45px;
        }
    }

    .top-links {
        ul {
            a {
                font-weight: 500;
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .owl-theme {
        .owl-nav {
            margin-top: 0px;
        }
    }

    button.owl-prev {
        span {
            font-size: 30px;
            color: #000;
        }
    }
    button.owl-next {
        span {
            font-size: 30px;
            color: #000;
        }
    }
}

@media screen and (max-width: 580px) {
    .carousel.slide .carousel-inner h1 {
        font-size: 35px;
    }

    header {
        .navbar {
            .navbar-nav {
                gap: 40px;
            }

            ul {
                li {
                    width: auto;
                    align-items: center;
                    text-align: center;
                    padding: 0 10px;
                    img {
                        position: relative;
                        right: 0px;
                        top: 0px;
                    }
                }
            }
        }

        .topbar {
            .top-links {
                ul {
                    align-items: center;
                    flex-direction: row;
                }
            }
        }
    }

    nav {
        .top-links {
            padding: 0;
        }
    }

    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    margin: 5px;
                }
            }
        }
    }

    .time-detail {
        .flex-inline {
            display: grid;
            .label {
                min-width: 180px;
            }
        }
    }

    .category-limbo {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .services {
        .listingpanel {
            grid-template-columns: repeat(1, 1fr) !important;
        }
    }

    .services .listingwrap .limboListing .listingpanel {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .product .listingwrap .limboListing .listingpanel {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .top-links {
        ul {
            li {
                &.btn-createlimbo {
                    right: 40px;
                    position: absolute;
                }
            }
        }
    }

    .registerWrap {
        h3 {
            label {
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }
    }

    .adsrow {
        flex-wrap: unset;
        .adDetail {
            h3 {
                word-break: break-word;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .registerWrap {
        h3 {
            margin-bottom: 30px;
            flex-direction: column;
            gap: 20px;
            label {
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }
    }

    .limbo-created {
        flex-direction: column;
        display: flex;
        time {
            padding-left: 0;
        }
    }

    .create-limbo-container {
        .form-container {
            .button-group #start {
                margin: 0;
            }
        }
    }

    .upload-button {
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        align-items: baseline;

        input[type='file'] {
            width: 100%;
        }
    }

    .limbo-details-section {
        padding: 20px 10px 30px;
    }

    .time-detail {
        .btn-section {
            grid-template-columns: 1fr 1fr;
        }
        .time-details {
            margin-bottom: 20px;
            padding: 15px 0 !important;
        }
    }
    span.list-created {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .product {
        .listingpanel {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .navbar {
        .navbar-nav {
            gap: 20px;
        }
    }
    .report__wrapper {
        align-items: center;
    }

    header {
        .navbar {
            .navbar-nav {
                gap: 25px;
            }
        }
    }

    .notification-page {
        .chatroom-container {
            .chat-page-title {
                font-size: 16px;
                padding: 10px 0;
                .notification-number {
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .limbo-details-section {
        .details-section {
            padding: 0 0px;
        }
    }
}
