.dropdown {
    .dropdown-input {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-shrink: 0;
        border: 1px solid #ccc;
        background: #fff;

        input[type='text'] {
            border: unset !important;
            width: 85%;
            padding: 0;
        }
        img{
            width: 15px;
            height: 15px;
        }
    }

    .dropdown-list {
        width: 100%;
        border: 1px solid #ccc;

        background: #fff;
        .search-bar {
            @extend .dropdown-input;
            flex-shrink: 0;
            margin: 1rem;
            color: #9a9a9a;
        }

        .list {

            max-height: 200px;
            overflow: auto;

            .list-group {
                .list-group-item {
                    border: none;
                    color: #9a9a9a;
                    cursor: pointer;
                    padding: 5px 10px;
                    font-size: 12px;
                    &:hover {
                        background-color: #e2dede;
                        color: black;
                    }
                }
            }
        }
    }
}
