.limbo-panel {
    &__modal {
        .modal {
            &-content {
                padding: 1rem;
                border-radius: 20px !important;

                .modal {
                    &-header {
                        border: none !important;
                    }

                    &-footer {
                        border: none !important;

                        button {
                            &:nth-child(1) {
                                background-color: #E4E4E4 !important;
                                border-radius: 50px;
                                color: #424242;
                                font-weight: bold;
                                box-shadow: none !important;
                                margin-left:  1rem;
                            }

                            &:nth-child(2) {
                                box-shadow: none !important;
                                background-color: #FF9923 !important;
                                border-radius: 50px;
                            }

                        }
                    }
                }
            }
        }
    }
}