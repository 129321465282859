.userview {
    margin: 4rem;

    // padding: 20px;
    form {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .form-group {
            width: 45%;
            align-items: center;
            label {
                width: auto;
                margin-right: 10px;
                display: flex;
                align-items: center;
            }
            .value {
                width: 100%;
            }
            .select-dropdown {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .userview {
        padding: 0px 10px;
        form {
            gap: 5px;
            .form-group {
                width: 48%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .userview {
        form {
            .form-group {
                width: 47%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .userview {
        form {
            flex-direction: column;
            .form-group {
                width: 100%;
                margin-bottom: 0 !important;
            }
        }
    }
}

.update-categories {
    width: 100%;
    text-align: center;
    font-size: 28px;
    border-top: 1px solid black;
    padding-top: 1rem;
}
