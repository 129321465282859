@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.limbo-panel {
    margin-left: 30px;
    width: 100%;
    @include devices(tablet) {
        width: 100%;
        margin-left: 0;
    }
    .time-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;

        .limbo-created-time {
            color: $theme-blue;
            text-decoration: none !important;
        }

       &.time-section {
            flex-direction: row;
        }

    }
    .limbo-title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        margin-bottom: 5px;
        color: $theme-blue;
        border-bottom: solid 1px #dbdbdb;
    }

    .flex-inline {
        display: grid;
        grid-template-columns: 40% 60%;
        align-items: center;
        margin-bottom: 0px;
        padding: 8px 0;
        border-bottom: 1px solid #ebebeb;

        .label {
            font-weight: 600;
            margin-right: 40px;
        }
        .label_name {
            line-height: 30px;
            color: #2b2857;
        }

        // &:nth-last-child(3) {
        //     // @include flex-direction-flex-start;
        //     .label {
        //         margin-bottom: 10px;
        //     }
        // }
        // &:nth-last-child(4) {
        //     // @include flex-direction-flex-start;
        //     .label {
        //         margin-bottom: 10px;
        //     }
        // }
        // &:last-child {
        //     // @include flex-direction-flex-start;
        //     .label {
        //         margin-bottom: 10px;
        //     }
        // }
    }
    ul {
        li {
            display: flex;
            align-items: center;
            .label {
                margin-right: 50px;
                font-weight: 700;
                color: $light-black;
                text-transform: uppercase;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .time-detail {
        .time-details {
            padding-bottom: 20px;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }
        .limbo-title {
            gap: 20px;
            flex-direction: column-reverse;
        }
    }
}

@media screen and (max-width: 767px) {
    .time-detail {
        margin-left: 0;
    }

    .time-detail {
        .limbo-title {
            flex-direction: row;
        }

        .time-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            border-bottom: 1px solid #dbdbdb;
            padding-bottom: 40px;
            flex-direction: row;
        }
    }
}

@media screen and (max-width: 540px) {
    .time-detail {
        .time-details {
            display: flex;
            align-items: self-start;
            flex-direction: column;
        }
        .limbo-title {
            flex-direction: column-reverse;
            .chat-vendor {
                img {
                    width: 25px;
                }
            }
            .chat-vendor {
                justify-content: flex-end;
            }
        }
        .flex-inline {
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;
            flex-direction: column;
            border-bottom: 1px solid #dbdbdb;
            padding-bottom: 15px;
            .label {
                margin-bottom: 5px;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
