@import '../../style/variable.scss';
.forgotWrap {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1024px;
    align-items: center;
}
.forgotLeft {
    width: 50%;
    padding: 50px 5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include devices(tablet) {
        width: 100%;
    }
}
.forgotRight {
    width: 50%;
    padding: 50px 2%;
    box-sizing: border-box;
    @include devices(tablet) {
        width: 100%;
    }
}

.registerpage{
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem auto;
        svg{
            margin-left: 4px;
        }
    }
    &__text{
        display: flex;
        flex-direction: column;
        span{
            &:nth-child(1){
                font-size: 4rem;
                font-weight: 700;
                color: #a9cbff;
            }
            &:nth-child(2){
                font-size: 2.5rem;
                font-weight: 700;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.575);
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .forgotWrap {
        flex-direction: column;
    }

    .forgotLeft {
        width: 70%;
        padding: 10px;
    }

    .forgotRight {
        width: 80%;
        padding: 10px;
        box-sizing: border-box;
        button.btn.btn-primary.btn-block {
            padding-left: 30px;
            padding-right: 30px;
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 480px) {
    .forgotRight {
        width: 90%;
        padding: 10px;
        box-sizing: border-box;
    }
    .forgotLeft {
        width: 100%;
    }
}
