.adsrow {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
    @media screen and (max-width: 580px) {
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px;
    }
    .adImage {
        display: flex;
        width: 100px;
        img {
            width: 100px;
            height: 75px;
            object-fit: cover;
        }
    }
    .adDetail {
        display: flex;
        flex-direction: column;
        h3 {
            margin: 0;
            font-size: 16px !important;
            small {
                font-style: italic;
            }
        }
        p {
            margin: 0;
        }
    }
}
