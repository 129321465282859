@import '../../style/variable.scss';
.registerWrap {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 50px 10%;
    box-sizing: border-box;
    form {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    h3 {
        margin-bottom: 15px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .registeroptions{
            display: flex;
            gap: 5px;
            font-size: 18px;
            flex-direction: column;
        }
        label {
            width: auto;
            font-size: 18px;
        }
    }
}

.typewrap{
    display: flex;
    gap: 10px;
}

.phone-form-control {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 44px;
    .country-code {
        padding: 0.8rem;
        border-right: 1px solid #ccc;
        height: 44px;
        background-color: #ccc;
        border-radius: 10px;
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
    }

    .form-control {
        border: none !important;
        border-top-left-radius: 0%;
        border-bottom-left-radius: 0%;
    }
}

.registerLeft {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    padding-right: 2%;
    flex-direction: column;
    @include devices(tablet) {
        width: 100%;
    }
}

.registerRight {
    width: 50%;
    padding-left: 2%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include devices(tablet) {
        width: 100%;
    }
}

.otplinks {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
    .registerLeft {
        margin: 0 auto 50px;
    }
}
