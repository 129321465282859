@import './style/variable.scss';
@import './style/mixins.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
}

body {
    font-size: 14px !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
}

.btn,
button,
a,
ul,
li,
img,
h1,
h2,
h3,
h4,
h5,
h6,
p,
strong,
b {
    transition: all ease-in-out 0.25s;
}

body a {
    color: #016a37;
    font-weight: 500;
    &:hover {
        color: #000;
    }
}

ul {
    list-style: none;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

a {
    color: $black;
    text-decoration: underline;
}

h1 {
    color: $black;
    text-align: left;
    font-size: 45px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}

h2 {
    color: $light-black;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    padding-bottom: 20px;
}

.navbar {
    padding: 0 !important;
}

h3 {
    color: $black;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    margin-bottom: 25px;
}

h4 {
    font-weight: 600 !important;
}

p {
    color: $light-black;
    font-size: 13px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 22px;
    /* 214.286% */
    margin-bottom: 20px;
}

label {
    color: $light-black;
    font-size: 13px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px;
    width: 100%;
}

input[type='text'],
input[type='password'],
input[type='number'],
select,
input[type='email'] {
    border: 1px solid #ccc;
    height: 44px;
    background: $white;
    color: $light-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 30px;
    padding: 13px;
    box-sizing: border-box;
    width: 100%;
}

input[type='checkbox'] {
    margin-right: 8px;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

h3 {
    small{
        font-size: 14px;
        font-style: italic;
    }
}

.carousel.slide .carousel-indicators [data-bs-target] {
    width: 37px !important;
    height: 3px !important;
    padding: 0;
    background-clip: unset !important;
    background: #fff;
    border: 0;
    border-top: 0px solid transparent;
    border-bottom: 5px solid transparent;
}

.staticContent {
    max-width: 1400px;
    margin: 50px auto;
    padding: 0 30px;
}

.btn.btn-primary {
    @include button;
    background-color: $color-orange !important;
    color: $white;
    text-transform: capitalize;
}

.btn-orange {
    background: $color-orange;
    @include button;
    @media screen and (max-width: 620px) {
        font-size: 12px;
        padding: 7px 12px;
        font-weight: normal;
    }
    &:hover {
        background: $color-orangeHover;
    }

    &:focus-visible {
        color: $white !important;
    }
}

.btn-dark {
    background: $dark;
    color: $white;
    border: none;
    min-height: 50px;
    min-width: 157px;
    font-size: 14px;
    border-radius: 30px;
    padding: 0 35px;
    text-transform: uppercase;
    font-weight: 600 !important;
    transition: all ease 0.5s;

    &:hover {
        background: $color-darkHover !important;
    }

    &:focus-visible {
        color: $white !important;
    }
}

.container {
    max-width: 1400px !important;
    margin: 0 auto;
    padding: 0 30px !important;
}

.row > * {
    width: auto !important;
}

select.form-select {
    border-radius: 0;
    font-size: 14px !important;
}

.ads {
    margin: 30px auto 10px;
    max-width: 1400px;
    padding: 0 10px;
    img {
        width: 100%;
    }
}

.createads {
    padding: 0 40px;
    @media screen and (max-width: 580px) {
        padding: 0 10px;
    }
    .loader {
        margin-left: 10px;
    }
}

.mt-5 {
    margin-top: 25px;
}

.mt-10 {
    margin-top: 10;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mb-5 {
    margin-top: 25px;
}

.mb-10 {
    margin-top: 10;
}

.mb-15 {
    margin-bottom: 15px;
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    width: 100%;
}

.alert {
    font-size: 12px;
    margin-top: 5px;
}

.red,
.alert-danger {
    color: $red;
}

.btn-black {
    background: #3a3a3a;
}

.btn-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
}

.btn-report {
    color: #f00 !important;
    font-style: italic;
    cursor: pointer;
}

.tableView {
    width: 100%;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
}

th,
td {
    font-weight: 400 !important;
}

.errorText {
    color: red;
    padding: 0.5rem;
}

.responsive-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    select,
    input {
        height: 30px;
    }

    .sr-only {
        display: none;
    }

    button {
        border: 0;
    }

    .row {
        width: 100%;
        margin: 0;

        .col-sm-12 {
            width: 100% !important;
        }

        .col-md-6 {
            width: 50% !important;
        }
    }
}

.selectdropdown {
    width: 100%;

    input {
        height: 40px;
    }
}

.btn {
    text-transform: uppercase;
}

textarea {
    padding: 10px;
    font-size: 12px !important;
    resize: none;
}

.card {
    .card-body {
        .card-text {
            color: #2b2857 !important;
            margin-bottom: 5px;
            height: 25px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.owl-carousel-section {
    .owl-nav {
        position: absolute;
        width: 100%;
        top: 0%;
        bottom: 0;
        margin: auto;
        height: 100%;
        z-index: -1;
    }
}

button {
    &.owl-prev {
        position: absolute;
        left: -40px;
        top: 30%;

        &:hover {
            background-color: transparent !important;
        }

        span {
            font-size: 50px;
            color: $black;
        }
    }

    &.owl-next {
        position: absolute;
        right: -40px;
        top: 30%;

        &:hover {
            background-color: transparent !important;
        }

        span {
            font-size: 50px;
            color: $black;
        }
    }
}

.carousel.slide {
    .carousel-inner {
        width: 100%;
    }
}

.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
    width: 40px;
    height: 40px;
}

.vendor-info-section {
    margin-top: 50px;
}

.form-group {
    margin-bottom: 15px !important;

    .alert {
        font-size: 12px;
        margin: -10px 0px !important;
        background: none !important;
        border: none !important;
        left: -15px;
        height: auto !important;
        color: #f00 !important;
    }
}

.chatroom-container .chat-title {
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    border-bottom: 0px solid black;
}

.margintop30 {
    margin-top: 30px !important;
}

.loading {
    max-width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

h5.no-records {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

div:where(.swal2-icon).swal2-warning {
    border-color: #ff0000 !important;
    color: #ff0000 !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 18px;
}

div:where(.swal2-icon) {
    border: 1px solid rgba(0, 0, 0, 0) !important;
    line-height: 5em;

    .swal2-icon-content {
        display: flex;
        align-items: center !important;
        font-size: 30px !important;
    }
}

.no-records {
    width: 100%;
    text-align: center;
    padding: 50px 0;
    justify-content: center;
}
.limbos__table, .dashboard-listing-list, .admin__table{
    position: relative;
}
.filterdropdown{
    display: flex;
    width: auto;
    gap: 10px;
    align-items: center;
    position: absolute;
    z-index: 999;
    right: 220px;
    top: 19px;
    p{
        margin: 0;
    }
    select{
        width: 200px;
        height: 30px;
        padding: 5px;
    }
}
.limbo-list{
    padding:  0 15px;
}
.category-card {
    cursor: pointer;
}

@media screen and (max-width: 1440px) {
    .ads {
        padding: 0 20px;
    }
}

@media screen and (max-width: 767px) {
    .h4,
    h4 {
        font-size: calc(0.9rem + 0.3vw) !important;
    }

    .ads {
        padding: 20px;
        margin: 0;
    }

    .form-select {
        padding: 0 20px !important;
        font-size: 14px !important;
        background-position: right 0.3rem center !important;
        background-size: 12px 10px !important;
        height: 45px;
    }
    table td,
    table th,
    .pagination .page-item .page-link {
        font-size: 12px !important;
    }
    body {
        font-size: 12px !important;
    }
}
