$white: #fff;
$color-orange: #ff9111;
$color-orangeHover: rgb(255 129 0);
$light-gray: #ddd;
$light-green: #b6e2c5;
$black: #000;
$light-black: #424242;
$grey: #5b5b5b;
$dark-grey: #cccccc;
$light-grey: #f7f7f7;
$red: red;
$blue: #2b2857;
$font-abel: 'Abel';
$gray-shade: #818181;
$theme-blue: #36afc9;
$dark: #525252;
$color-darkHover: $black;
@mixin devices($breakpoint) {
    //the name of the mixin is devices

    @if $breakpoint == lg-screen {
        @media only screen and (min-width: 1920px) {
            @content;
        }
    }

    @if $breakpoint == md-screen {
        @media only screen and (max-width: 1180px) {
            @content;
        }
    }

    @if $breakpoint == tablet {
        @media only screen and (max-width: 720px) {
            @content;
        }
    }

    @if $breakpoint == mobile {
        @media only screen and (max-width: 481px) {
            @content;
        }
    }
}
