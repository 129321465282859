@import '../../style/variable.scss';
@import '../../style/mixins.scss';

footer {
    background-color: $light-green;
    padding: 40px 0px;

    h5 {
        font-weight: 600;
        font-size: 18px;
    }

    .footer {
        max-width: 1400px;
        margin: 0 auto;
        margin: 0 auto;
        display: grid;
        column-gap: 80px;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 30px;
        a {
            color: $black;
        }
        .col {
            padding-right: 50px;
            border-right: 1px solid #afafaf;
            ul {
                p {
                    &.show-map {
                        padding: 15px 0;
                    }
                }
                ul {
                    display: flex;
                    gap: 30px;

                    &.social-icons {
                        padding: 0;
                    }
                }

                li {
                    margin-bottom: 8px;
                    font-weight: 500;

                    &.bottom-footer {
                        display: flex;
                        h6 {
                            margin-top: 6px;
                            font-size: 15px;
                            margin-bottom: 0;
                            font-weight: 600;
                        }
                        p {
                            margin: 0 !important;
                            font-size: 12px;
                            font-weight: 700;
                            color: #000;
                        }
                        img {
                            margin-right: 15px;
                        }
                    }
                    strong {
                        font-weight: 600;
                    }
                }
            }
            &:last-child {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                border: none;
                ul {
                    display: grid;
                    align-items: flex-start;
                    justify-content: space-between;
                }
            }
        }
    }
}

/*media query start*/

@media screen and (max-width: 1440px) {
    footer {
        padding: 84px 20px;
    }
}

@media screen and (max-width: 1170px) {
    footer {
        .footer {
            column-gap: 15px;
            font-size: 14px;
            .col {
                padding: 0px 20px;
                &:last-child {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    footer {
        padding: 45px 25px;
        .footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .col {
                padding: 0px 0px;
                width: 90%;
                &:last-child {
                    width: 100%;
                    padding: 40px 0 0;
                    grid-column: 1/-1;
                    border-top: 1px solid #afafaf;
                }
                &:nth-child(3n - 1) {
                    border: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    footer {
        .footer {
            gap: 40px;
            grid-template-columns: 1fr;
            .col {
                border: none;
                border-bottom: 1px solid #afafaf;
                width: 100%;
                &:last-child {
                    padding: 30px 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .m-show {
        display: flex !important;
    }

    .m-hide {
        display: none !important;
    }

    footer {
        .footer {
            gap: 20px;
            .col {
                &:last-child {
                    padding: 10px 0 0px;
                    grid-template-columns: 1fr !important;
                    display: grid;
                    ul {
                        border-bottom: 1px solid #afafaf;
                        padding: 20px 0;
                    }
                }
            }
        }
    }
}
