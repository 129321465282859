.product {
    .listingpanel {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
        gap: 15px;
        @media screen and (max-width: 920px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 620px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
