.dashboard-shop-Category-section {
    h4 {
        padding: 0;
        margin: 0;
        font-weight: 600;
    }

    .limbo-heading {
        background-color: #f7f7f7;
        padding: 10px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .item{
        border: solid 1px #ccc;
        padding: 15px;
        width: 100%;
        display: flex;
        cursor: pointer;
    }
}


.limbo-links {
    display: flex;
    gap: 4px;

    a {
        background: #ffffff;
        border-radius: 4px;
        padding: 2px 15px;
        border: 1px solid #b6e2c5;
    }
}


@media screen and (max-width: 1440px) {
    .dashboard-shop-Category-section {
        padding: 0 20px;
    }
}