.report__table {
    table {
        tr {
            td:nth-child(1) {
                overflow: hidden;
                text-overflow: ellipsis;
                height: 2rem;
                white-space: nowrap;
                max-width: 6rem;
            }
            td,
            th {
                padding-left: 1rem !important;
            }
        }
    }
    .viewed {
        cursor: pointer;

        svg {
            color: rgb(10 204 21);
        }
    }

    .isChecked {
        svg {
            cursor: pointer;
        }
    }
}

.report__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .btn.btn-primary {
        padding: 1rem;
    }
}
