@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.navbar {
    justify-content: space-between;
    .navbar-nav {
        height: 60px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        transition: all ease 0.5s;
        a {
            color: black;
            &.active {
                font-weight: 500;
            }
        }
    }
}
.dashboardnav{
    padding-left: 30px;
}
@media screen and (max-width: 480px) {
    .navbar .navbar-nav {
        gap: 30px;
    }
}
