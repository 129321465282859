@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.time-detail {
    margin-left: 30px;

    .time-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        border-bottom: 1px solid #dbdbdb;
        padding-bottom: 20px;
        a {
            color: $theme-blue;
            text-decoration: none !important;
        }
    }
    .limbo-title {
        display: flex;
        margin-bottom: 20px;
       justify-content: space-between;
       flex-direction: row;
    }

    .flex-inline {
        display: grid;
        align-items: center;
        margin-bottom: 18px;
        grid-template-columns: 1fr 1fr;
        .label {
            font-weight: 700;
            margin-right: 40px;
            text-transform: uppercase;
            min-width: 160px;
            position: relative;
            &::after {
                position: absolute;
                content: ':';
                right: 0;
                font-weight: 400;
            }
        }
        .label_name {
            line-height: 30px;
            color: #2b2857;
        }
    }

    .limbo {
        &--details {
            ul {
                li {
                    display: flex;
                    align-items: center;
                    .label {
                        margin-right: 50px;
                        font-weight: 700;
                        color: $light-black;
                        text-transform: uppercase;
                    }
                }
            }
            h6{
                margin-bottom: 20px;
                font-weight: bold;
            }
            .limbo-item {
                border-bottom: 1px solid #dbdbdb;
                padding-bottom: 20px;
                margin-bottom: 0px;
            }
        }
    }

    .report__wrapper {
        display: flex !important;
        justify-content: space-between;
    }
    .btn-section {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        padding: 15px 0;
    }
}

@media screen and (max-width: 1200px) {
    .time-detail {
        .time-details {
            padding-bottom: 20px;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }
        .limbo-title {
            gap: 20px;
         }
    }
}

@media screen and (max-width: 767px) {
    .time-detail {
        margin-left: 0;
    }

    .time-detail {
        .limbo-title {
            flex-direction: row;
        }

        .time-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            border-bottom: 1px solid #dbdbdb;
            padding-bottom: 40px;
            flex-direction: row;
        }
    }
}

@media screen and (max-width: 540px) {
    .time-detail {
        .time-details {
            display: flex;
            align-items: self-start;
            flex-direction: column;
        }
        .chat-vendor {
            img {
                width: 25px;
            }
        }
        .chat-vendor {
            justify-content: flex-end;
            display: flex;
            gap: 10px;
            margin-bottom: 5px;
        }
        .limbo-title {
            flex-direction: column-reverse;
            
        }
        .flex-inline {
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;
            flex-direction: column;
            border-bottom: 1px solid #dbdbdb;
            padding-bottom: 15px;
            .label {
                margin-bottom: 5px;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
