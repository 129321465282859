@import '../../style/variable.scss';
.loginWrap {
    display: grid;
    width: 100%;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
}
.loginLeft {
    padding: 40px 80px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    a {
        color: #016A37;
    font-weight: 500;
    }
    @include devices(tablet) {
        width: 100%;
    }
}
.loginRight {
    background: $light-grey;
    padding: 20px 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    li {
        margin-bottom: 20px;
        color: $grey;
        display: flex;
        align-items: center;
        .icon {
            margin-right: 20px;
        }
    }
    .loginlogo {
        margin-bottom: 52px;
        width: 250px;
    }
}
.showpassword-row{
    display: flex;
    justify-content: space-between;
    label{
        width: 50%;
    }
}

.login{
    &__btn{
        .loading{
            height: auto !important;
        }
    }}

.loginForm {
    display: flex;
    flex-direction: column;
}
.forgotlink {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
    color: $light-black;
    margin-bottom: 25px;
}

.loginlogo {
    margin-bottom: 52px;
}

.btn-block.login__btn {
    width: 110px;
}



@media screen and (max-width: 991px) {
    .loginLeft {
        padding: 80px 30px !important;
    }
    .loginRight {
        padding: 80px 30px !important;
    }
}

@media screen and (max-width: 767px) {
    .loginWrap {
        grid-template-columns: 1fr;
    }

    .forgotlink {
        padding: 20px 0 40px;
    }
    .loginLeft a {
        line-height: 26px;
    }
    .loginLeft {
        width: 100%;
    }

    .loginRight {
        width: 100%;
    }
}
