@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.limbo-details-section {
    margin: 0 auto;
    max-width: 1400px;

    padding: 20px 30px 0;

    .details-section {
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 20px 0;

        .imgPanel {
            img {
                // max-width: 80vw;
            }
        }

        h3 {
            color: #000;
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }

        button {
            &.btn-orange {
                max-width: max-content;
            }
        }
    }

    a {
        color: $theme-blue;
        text-decoration: underline;
    }
}

@media screen and (max-width: 1440px) {
    .limbo-details-section .details-section {
        padding: 0 20px;
    }
}

@media screen and (max-width: 767px) {
    .limbo-details-section {
        .details-section {
            display: grid;
            grid-template-columns: 1fr;
        }
    }

    .time-detail {
        padding: 0.5rem;
        .time-details {
            padding-bottom: 20px;
            flex-direction: row;
            gap: 20px;
            align-items: center;
        }

        .limbo-title {
            flex-direction: row;
        }
    }
}

@media screen and (max-width: 750px) {
    .imgPanel {
        img {
            max-width: 80vw;
        }
    }
}

@media screen and (min-width: 750px) {
    .imgPanel {
        img {
            max-width: 45vw;
        }
    }
}

