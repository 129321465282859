/* 
 * Always set the map height explicitly to define the size of the div element
 * that contains the map. 
 */
/* #map {
    height: 100%;
} */

/* 
   * Optional: Makes the sample page fill the window. 
   */
/* html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 0 0.5em;
    font: 400 18px Roboto, Arial, sans-serif;
    overflow: hidden;
    font-family: Roboto;
    padding: 0;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
    z-index: 1000;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

#target {
    width: 345px;
} */

.modal.show .modal-dialog {
    max-width: 70%;
}

.pac-container {
    max-width: 70%;
    z-index: 100000;
}
.search-section {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-orange {
        margin-left: 10px;
    }
}
