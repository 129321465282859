@import '../../style/mixins.scss';
@import '../../style/variable.scss';

.limbo-list {
    ul {
        li {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            background: #f8f8f8;
            width: 100%;
            height: auto;
            padding: 10px 20px;
            margin-bottom: 10px;
            @media screen and (max-width: 620px) {
                margin-bottom: 0px;
                padding: 10px;
            }
        }
    }
}
.hdrow {
    display: flex;
    justify-content: space-between;
}
.time-details {
    gap: 15px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}
.limbo-list-desc {
    display: grid;
    grid-template-columns: 1fr 180px;
    gap: 40px;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 980px) {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 620px) {
        align-items: flex-start;
    }
    h4 {
        color: $black;
        font-size: 18px !important;
        font-weight: normal !important;
        margin-bottom: 0px;
        @media screen and (max-width: 580px) {
            font-size: 14px !important;
            line-height: 24px;
        }
        small {
            color: $color-orange;
            font-size: 14px !important;
            font-weight: bold;
            margin-bottom: 0;
            font-style: italic;
        }
    }

    p {
        margin-bottom: 0;
    }
    .limbo-content {
        display: grid;
        gap: 10px;
    }
}

.icons {
    display: flex;
    max-width: 300px;
    gap: 15px;
    justify-content: center;
    svg {
        width: 20px;
    }
}

.btn-gray {
    background: $gray-shade;
    font-weight: 600;
    color: #fff !important;
    &:hover {
        background: $light-black;
    }
}
.btn-orange {
    background: $color-orange;
}

.list-img img {
    cursor: pointer;
    width: 100px;
    height: 80px;
    object-fit: cover;
}

@media screen and (max-width: 1366px) {
    .admin-list {
        ul {
            li {
                height: auto;
            }
        }
    }
}
