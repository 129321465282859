@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.vendorinfo-label {
    border-bottom: 1px solid rgba(102, 102, 102, 0.2);
    margin-bottom: 40px;
    padding-bottom: 2px;
    position: relative;

    h4 {
        position: relative;
        display: inline;
        border-bottom: 3px solid #424242 !important;
        bottom: 0px;
        padding-bottom: 3px;
        color: #818181;
    }
}

.vendor-info {
    margin-bottom: 40px;

    .flex-inline {
        
        .label {
            line-height: 30px;
        }
    }
}

@media screen and (max-width: 1440px) {
    .vendor-info-section {
        padding: 0 20px;
    }
}

@media screen and (max-width: 540px) {
    .vendor-info {
        .flex-inline {
            grid-template-columns: 1fr;
        }
    }
}
