@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.chat-container {
    width: 320px;
    height: 280px;
    background-color: #f5f5f3;
    position: fixed;
    bottom: 122px;
    right: 1px;
    border-radius: 8px;
    z-index: 11;

    .chat-title {
        height: 60px;
        background-color: #36afc9;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        padding-left: 20px;
        padding-top: 6px;
        padding-right: 20px;
        color: #fff;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        position: relative;

        .name {
            font-size: 20px;
        }

        .status {
            font-size: 12px;
        }

        .threedot {
            position: absolute;
            right: 8px;
            top: 16px;
            font-size: 24px;
        }

        .day {
            position: absolute;
            text-align: center;
            background-color: #fff;
            color: black;
            min-width: none;
            z-index: 3;
            padding: 8px;
            padding-left: 32px;
            padding-right: 32px;
            border-radius: 16px;
            margin-left: 120px;
            top: 70px;
        }
    }

    .chat-body {
        height: 100%;
        overflow-y: scroll;
        background-color: #f5f5f3;
        display: flex;
        flex-direction: column;
        position: relative;

        .messageContainer {
            // min-width: 380px;
            display: flex;
            flex-direction: row-reverse;

            .text-right {
                // align-items: flex-start;
                justify-content: flex-start;
                background-color: #36afc9;
                max-width: fit-content;
                // text-align: right;
            }

            .text-left {
                // align-items: flex-end;
                justify-content: flex-end;
                max-width: fit-content;
                background-color: #fff;
                // text-align: left;
                margin: 0px auto 0px auto;
            }

            .message-box {
                padding: 4px;
                border-radius: 12px;
                padding: 8px;
                margin-left: 16px;
                margin-bottom: 8px;
                margin-top: 12px;
                padding-left: 16px;
                padding-right: 16px;

                .sender {
                    font-size: 10px;
                }

                .message {
                    word-wrap: break-word;
                }
            }
        }
    }

    .react-input-emoji--input {
        padding: 19px 12px 11px !important;
    }

    .send-field {
        position: relative;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        input {
            padding-left: 36px;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .react-emoji {
            position: absolute !important;
            top: 0px;

            .react-input-emoji {
                &--container {
                    border-radius: 0px !important;
                    margin: 0;
                    height: 60px;
                    border-bottom-right-radius: 8px !important;
                    border-bottom-left-radius: 8px !important;
                }

                &--wrapper {
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &--placeholder {
                    padding-left: 32px;
                }

                &--input {
                    margin-left: 32px;
                    margin-right: 60px;
                    height: 60px;
                    align-items: center;
                    z-index: 1;
                    overflow: hidden;
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &--button {
                    position: absolute;
                    left: 4px;
                    bottom: 18px;

                    &--icon {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

        .files {
            position: absolute;
            top: 15px;
            font-size: 18px;
            right: 55px;
            z-index: 2;

            .file-input {
                display: none;
            }
        }

        .enter {
            position: absolute;
            top: 15px;
            font-size: 20px;
            right: 20px;
            z-index: 2;
        }
    }
}
