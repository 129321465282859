.image-picker {
    .cross-button {
        background: none !important;
        box-shadow: none;
        padding: 0rem;
        position: relative;
        top: -5rem;
        bottom: 0rem;
        left: 2rem;
        svg {
            fill: black;
        }
    }

    .selected-images-container {
        .preview-image-container {
            height: 4rem;
        }
        display: flex;
        margin-top: 0rem;
        // height: auto;
    }
}
