@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.quantity {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin: 5px 0 10px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 25px;
    align-items: center;
    button {
        border-radius: 0;
        border: 1px solid #c4c4c4 !important;
        min-height: 60px;
        margin: 0;
        box-shadow: none;
    }
    input {
        border-radius: 0;
        border: 1px solid #dcdcdc !important;
        text-align: center;
        min-height: 60px;
        &[type='number'] {
            -moz-appearance: textfield;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .change-offer-btn {
        display: grid;
        grid-template-columns: 80px 150px 80px;
        gap: 10px;
    }
}

.place-an-offer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 35px;
    margin-bottom: 20px;
    width: 100%;
}

.btn-update {
    background: #00b95f;
    border: none !important;
    border-radius: 0;
    min-height: 60px;
    border: none !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    &:hover {
        background: #00a354;
    }
}

.place-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    .hide {
        // display: none;
        img {
            width: 22px;
            margin-right: 5px;
            position: relative;
            top: -1px;
        }
    }
}

p.price-updated {
    text-align: center;
    padding: 20px 0;
    border: 1px solid #dddddd73;
    box-shadow: -2px 0px 15px #dddddda8;
}
