@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.limbo-slider {
    margin-bottom: 10px;

    img {
        width: 100%;
    }
    .carousel {
        border: 1px solid #dbdbdb;
        padding: 12px;
        max-width: 633px;
        .carousel-indicators {
            bottom: -40px;
            margin: 10px 0 2px;
            [data-bs-target] {
                flex: none !important;
                width: 40px !important;
                padding: 5px !important;
                text-indent: -999px !important;
                border: 0 !important;
                border-top: 5px solid #d9d9d9 !important;
                border-bottom: 5px solid transparent !important;
                opacity: 0.5 !important;
                transition: opacity 0.6s ease !important;
            }
            .active {
                opacity: 1 !important;
                border-top: 5px solid #ff9923 !important;
            }
        }
    }
}

.limbo-slider.detail-page-slider {
    .carousel.slide {
        height: auto;
    }
}

.listingpanel {
    .listview {
        .carousel-inner {
            width: 350px !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .limbo-slider {
        margin-bottom: 100px;
        .carousel {
            border: 1px solid #dbdbdb;
            padding: 12px;
            max-width: 100%;
        }
    }
}
