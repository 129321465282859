@import "../../style/mixins.scss";

.subscription {

    &__title {
        height: 110px;
        background: #B6E2C580;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 45px;
        font-weight: 400;
    }

    &__header {
        margin: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 620px) {
            margin: 20px 0;
        }
        h1 {
            font-size: 45px;
            font-weight: 700 !important;
            text-transform: uppercase;
        }

        p {
            padding: 0 16px;
            width: 70%;
            text-align: center;
        }
    }

    &__card {
        width: 304px;
        border-radius: 20px;
        background: #D3E9DA;
        display: flex;
        flex-direction: column;
        margin: 27px;
        height: min-content;
        @media screen and (max-width: 620px) {
            margin: 10px;
        }
        &__wrapper {
            @include justifyCenter;
            margin-bottom: 105px;
            @media screen and (max-width: 620px) {
                margin-bottom: 10px;
            }
        }

        &__top {
            padding: 22px 22px 0 22px;
            border-bottom: 1px solid #FFFFFF;
        }

        &__bottom {
            padding: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        &__plan {
            height: 28px;
            max-width: 110px;
            border-radius: 50px;
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 26px;
            padding: 4px;
        }

        &__price {
            margin-bottom: 13px;

            &__number {
                font-size: 28px;
                font-weight: 700;
                color: #000000;
            }

            &__duration {
                font-size: 18px;
                font-weight: 600;
                color: #6D6D6D;
            }
        }

        &__desc {
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 24px;
        }

        &__list {

            &__item {
                margin: 8px 0;
                @include alignCenter;
                height: 30px;

                svg {
                    margin-right: 4px;
                }
            }
        }

        &__btn {
            width: 124px;
            height: 40px;
            border-radius: 50px;
            background: #91B09B;
            font-size: 12px;
            font-weight: 600;
            border: none;
            color: #ffffffd2;
            .loading{
                height: unset !important;
            }
        }

        &:nth-child(2) {
            background: #7EB08F !important;

            div,
            span {
                color: white;
            }

            .subscription {
                &__card {
                    &__plan {
                        color: #000 !important;
                    }

                    &__btn {
                        background: #51775E !important;
                    }
                }
            }
        }
    }

    &__skipBtn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }
}


@media screen and (max-width: 992px) {
    .subscription__header {
        h1 {
            font-size: 32px;
        }

        p {
            width: 80%;
            text-align: center;
        }
    }
}

@media screen and (max-width: 768px) {
    .subscription {
        &__header {
            h1 {
                font-size: 26px;
            }

            p {
                width: 100%;
            }
        }

        &__card__wrapper {
            flex-direction: column;
            @include center;
        }
    }
}

@media screen and (max-width: 576px) {
    .subscription {
        &__header {
            h1 {
                font-size: 22px;
                text-align: center;
                padding: 0 8px;
            }
        }

        &__title {
            font-size: 36px;
        }
    }

}