@import '../../style/variable.scss';
.searchPage {
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.searchrow {
    cursor: pointer;
    display: flex;
    width: 45%;
    gap: 20px;
    margin: 20px;
    border: 1px solid #b6e2c5;
    padding: 1rem;

    .searchImg {
        width: 200px;
        img {
            max-width: 200px;
        }
    }
}

.nodata {
    padding: 0;
    text-align: center;
    font-size: 30px;
    width: 100%;
    max-width: 100%;
    background: #b6e2c514;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
