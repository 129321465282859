.category {
    &__img {
        img {
            margin: 0 !important;
            height: 50px;
        }

        svg {
            color: #00C208;
        }
    }

    &__update {
        .form-check {
            display: flex;
            margin: 0.5rem;
        }

        &__modal {
            .modal-content{
                padding: 1rem;
                border-radius: 0.5rem !important;
            }

            .modal-footer {
                button {
                    height: 2rem !important;
                    max-height: unset !important;
                    min-height: 2rem !important;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    flex-wrap: wrap !important;
                    border-radius: 1rem;
                    width: 8rem !important;
                    padding: 20px 65px;
                    &:first-child {
                        background-color: #424242  !important;
                        color: #ffffff !important;
                        font-weight: 600 !important;
                        font-size: 1rem !important;
                    }
                    &:nth-child(2) {
                        font-size: 1rem !important;
                        font-weight: 600 !important;
                        background-color: #FF9923 !important;
                    }
                }
            }
        }
    }
}