@import './variable.scss';
@import './mixins.scss';

.react-datepicker-wrapper {
    width: 100%;
}

.tableView {
    &__approved {
        .checkIcon {
            color: green;
        }
    }

    &__rejected {
        .crossIcon {
            color: red;
        }
    }

    &__expired {
        cursor: not-allowed !important;
    }
}

.admin {
    &__table {
        thead[data-test='table-foot'] {
            display: none;
        }

        &__csv {
            tr {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                width: 100%;
            }

            td {
                display: grid;
                align-items: center;
                width: unset !important;
                padding: 0 !important;
                height: 50px !important;

                .category__img img {
                    padding: 0 !important;
                    max-height: 50px !important;
                }

                max-height: 50px !important;
            }
        }

        &__createCat {
            display: flex;
            justify-content: flex-end !important;

        }

        button {
            background: transparent;
        }

        img {
            width: 75px;
            height: 50px;
            object-fit: cover;
        }

        svg {
            margin-left: 1rem;
            cursor: pointer;
        }

        th {
            color: #000;
            font-weight: 700;
            line-height: 24px;
        }

        td {
            height: 50px;

            td {
                text-align: left;
            }
        }
    }
}

.upload {
    display: flex;
    margin: 1rem 0;

    @media screen and (max-width: 580px) {
        flex-wrap: wrap;
    }

    .loading {
        width: auto !important;
        max-width: auto !important;
        height: auto !important;
    }
}

.payment__cancel {
    display: flex;
    align-items: center;
    margin-left: 5rem;
    justify-content: center;

    img {
        height: 30rem;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12rem;
            height: 4rem;
            padding: 0 !important;
            margin: 2rem auto;

            svg {
                font-size: 1rem;
                margin-left: 0.5rem;
            }
        }
    }

    &__text {
        margin-left: 5rem;
        font-size: 2rem;
        display: flex;
        flex-direction: column;

        span {
            font-weight: 600;

            &:nth-child(1) {
                color: #ff776b;
                font-size: 4rem;
            }

            &:nth-child(2) {
                color: #bdd0fb6e;
                font-size: 2.5rem;
            }
        }
    }
}

.dashboard-listing-list {
    .limbos__subscription {
        width: auto !important;

    }
}

.buy__subscription {
    display: flex;
    justify-content: flex-end;
}

// .buy__subscription{
.limbo__subscription__btn {
    border: 1px solid #3e3e57;
    background: transparent;
    padding: 5px 10px;
    border-radius: 8px;
    margin-right: 10px;

    @media screen and (max-width: 620px) {
        font-size: 12px;
        padding: 7px 12px;
        font-weight: normal;
        margin-right: 10px;
    }



    &__renew {
        cursor: pointer;
    }
}

.paymentStatus {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    &__success {
        color: #00B95F !important;

        button {
            cursor: not-allowed !important;
        }
    }

    &__failure {
        color: #ff0000 !important;
    }

    .refetchPayment {
        height: 1rem !important;
        width: 2rem !important;
        font-size: 0.7rem;
        font-weight: 100 !important;
        letter-spacing: 0.1ch;
    }
}

// }
.limbos {
    &__subscription {

        display: flex;
        height: 2rem;
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width: 580px) {
            flex-direction: column;
            height: auto;
            gap: 5px;
        }

        div {
            font-size: 1.1rem;
            // &:nth-child(2){
            color: $color-orange;
            font-weight: 500;
            // }
        }

        button {
            height: 2rem;
            width: 4rem;
            border-radius: 8px;
            margin: 0 1rem;
            background: transparent !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .plan__expired {
            color: #ff0000;
            border: 1px solid red;
        }

        .plan__active {
            color: #00B95F;
            border: 1px solid #00B95F;

        }
    }
}

.createListing {
    display: flex;
    justify-content: flex-end;


}

// reusable classes
.approved {
    color: #00c208;
    text-transform: capitalize;
}

.rejected {
    color: #ff0000;
    text-transform: capitalize;
}

.pending {
    color: #d7c100;
    text-transform: capitalize;
}

.loader {
    &__table {
        height: 50vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .loader {
            height: 2rem;
            width: 2rem;
        }
    }
}

.errorBoundry {
    display: flex;
    align-items: center;
    justify-content: center;

    &__img {
        height: 40rem;
        width: 50vw;
    }

    &__text {
        color: #d6d6d6;
        font-weight: 800;
        font-size: 4rem;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
    }
}

.icon {
    &__wrapper {
        button.btn {
            padding: 3px 10px !important;
            height: auto;
        }

        svg {
            cursor: pointer;
            margin: 0;
        }
    }
}

button.popover__actions__btn.btn.btn-primary {
    background-color: #121111 !important;
}

.popover {
    &__actions {
        width: 7rem;

        &__btn {
            background-color: transparent !important;
            height: 2rem !important;
            width: 3rem !important;
            padding: 0 !important;
            border-radius: 24px !important;
            font-size: 1.5rem !important;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                margin: 0 !important;
                padding: 0 !important;
            }

        }

        .popver__actions__btn__wrapper {
            display: flex;
            align-items: center;
            font-size: 0.9rem;
            cursor: pointer;
            overflow: hidden;

            svg {
                margin-right: 6px;
            }
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 18px;
        padding: 7px;
        background-color: transparent !important;
    }
}

.pagination .page-item.active .page-link {
    background-color: $color-orange !important;
}

.form-check-input:checked {
    background-color: $color-orange !important;
    border-color: $color-orange !important;
}

.staticPage {
    h4 {
        font-size: 18px;
        padding-bottom: 15px;
        text-decoration: underline;
    }

    ul {
        list-style: disc;
        padding-left: 15px !important;
        line-height: 30px;
        color: #424242;
        font-size: 13px;
        padding-bottom: 20px;
    }

    ol {
        list-style: auto;
        padding-left: 15px !important;
        line-height: 30px;
        color: #424242;
        font-size: 13px;
        padding-bottom: 20px;
    }

}


@media screen and (max-width:1024px) {
    .errorBoundry {
        flex-direction: column;

        &__img {
            height: 20rem;
            width: 20rem;
        }

        &__text {
            color: #d6d6d6;
            font-weight: 800;
            font-size: 3rem;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
        }
    }
}