@import '../../style/mixins.scss';
@import '../../style/variable.scss';

.contact-form {
    max-width: 650px;
    margin: 0 auto;
    filter: drop-shadow(0px 10px 18px rgba(90, 90, 90, 0.25));
    background: #fff;
    margin: 50px auto;
    padding: 50px 50px 20px;
    border-radius: 15px;
    border: 1px solid #e4e4e4;
    transition: all ease 0.5s;

    .btn-orange {
        color: #fff;
        background-color: #f57c00 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        cursor: pointer;
        user-select: none;
        grid-column: 1/-1;
    }
}

.justify-content-center {
    max-width: 50%;
    margin: 0 auto;
    transition: all ease 0.5s;
}

.asterisk {
    color: $red;
}

p.message {
    margin-top: 40px;
    transition: all ease 0.5s;
}

.form-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    input {
        border-radius: 0;
        height: 55px;
        border-color: #ddd;
        transition: all ease 0.5s;
    }
    .textarea {
        grid-column: 1/-1;
        margin-bottom: 10px;
    }
    textarea {
        height: 130px;
        transition: all ease 0.5s;
        border-radius: 0;
    }
}
.message.order-md-12.col {
    grid-column: 1/-1;
}

@media screen and (max-width: 1366px) {
    .justify-content-center {
        max-width: 60%;
    }
}

@media screen and (max-width: 1170px) {
    .justify-content-center {
        max-width: 70%;
    }
}

@media screen and (max-width: 991px) {
    .justify-content-center {
        max-width: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .justify-content-center {
        max-width: 90%;
    }
    .contact-form {
        padding: 50px 20px 20px;
    }
}

@media screen and (max-width: 580px) {
    .form-section {
        grid-template-columns: 1fr;
    }
}
