.category-limbo {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
    max-width: 1400px;
    margin: 0 auto;

    gap: 20px;

    @media screen and (max-width: 900px) {
        gap: 5px;

    }

    .category-card {
        position: relative;
        display: flex;
        width: 23%;
        gap: 10px;
        align-items: center;

        img {
            width: 75px;
            height: 50px;
            object-fit: cover;
        }

        h3 {
            margin: 0;
            font-size: 16px !important;
            font-weight: bold;
        }

        @media screen and (max-width: 900px) {
            flex-direction: column;
        }
    }

    .category-card-choose {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
        max-width: 150px;
        height: auto;
        margin: 2rem;

        img {
            height: 150px;
        }

        h3 {
            margin-top: 1rem;
            font-size: 18px;
            font-weight: bold;
        }

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            // background-color: antiquewhite;
            .container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

            }

            .selected {
                color: lightgreen;
                font-size: 32px;
                font-weight: 500;
            }

            .unselected {
                color: lightcoral;
                font-size: 32px;
                font-weight: 500;
            }
        }
    }

    .selected {
        border: 1px solid lightgreen;
    }

    .unselected {
        border: 1px solid lightcoral;
    }
}