@import '../../../style/variable.scss';
@import '../../../style/mixins.scss';

.dashboard-limbo-listing-section {
    margin-bottom: 70px;
    .limbo-cards-section {
        display: grid;
        grid-template-columns: 1fr 320px;
        margin-top: 15px;
        padding: 0;
        gap: 25px;
        width: 100% !important;
        .limbo-cards {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 25px;
            .card {
                cursor: pointer;
                margin-bottom: 0px;
                box-shadow: none;
                height: 360px;
                border: 1px solid #ddd;
                box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
                    padding: 8px;
                img {
                    
                    border-radius: 0;
                }
                .card-body {
                    padding: 20px 0px 0;
                    .price{
                        color: #4CAF50
                    }
                    .card-title {
                        @include space-between;
                        h5{
                            font-size: 14px !important;
                        }
                        
                    }
                    p {
                        &.text-sm {
                            text-decoration: underline;
                            font-weight: 500;
                        }
                        .card-text {
                            margin-bottom: 10px;
                        }
                        a {
                            color: $black;
                        }
                    }
                }
            }
        }

        .dashboard-ad-2 {
            grid-column: 1/-1;
            .ads-2 {
                img {
                    width: 100%;
                }
            }
        }
    }

    .dashboard-sidebar {
        background: #f7f7f7;
        padding: 15px 15px;
        .sidenav-list {
            margin-top: 25px;
            ul {
                li {
                    padding: 5px 0;
                    display: flex;
                    gap: 10px;
                    font-size: 16px;
                    border-top: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                }
            }
        }

        .side-ads {
            margin-top: 20px;
            display: grid;
            gap: 30px;
            img {
                width: 100%;
            }
        }
    }

    h5 {
        font-size: 12px !important;
        text-transform: uppercase !important;
        font-weight: 600 !important;
    }

    .get-updates-section {
        @include justify-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
        margin-top: 25px;
        width: 100% !important;
        gap: 25px;
        padding: 0;
        img {
            width: 100%;
        }

        .get-update {
            padding: 0 130px;
            max-width: 100%;
            margin: 0 auto;
            gap: 15px;
            background: #fff;
            width: 100%;
            @include align-item-center-flex-direction;
            border: 25px solid #f1f1f1;
            input {
                border: 0;
                border-bottom: 1px solid #ddd;
                border-radius: 0;
                text-align: center;
                font-size: 14px;
                background: none;
                box-shadow: none;
            }
            img {
                margin: 0 auto;
                width: auto;
            }

            button {
                &.btn-black.btn.btn-dark {
                    width: 161px;
                    height: 50px;
                    border-radius: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .dashboard-limbo-listing-section {
        padding: 0 20px;
        .limbo-cards-section {
            grid-template-columns: 1fr 250px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .dashboard-limbo-listing-section {
        .limbo-cards-section {
            grid-template-columns: 1fr 200px;
            .limbo-cards {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .dashboard-limbo-listing-section {
        .limbo-cards-section {
            grid-template-columns: 1fr;
            .limbo-cards {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        .dashboard-sidebar {
            display: none;
        }
    }
}

@media screen and (max-width: 680px) {
    .dashboard-limbo-listing-section {
        .limbo-cards-section {
            .limbo-cards {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .dashboard-limbo-listing-section {
        .limbo-cards-section {
            .limbo-cards {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        .get-updates-section {
            grid-template-columns: 1fr;
        }
    }
}
