.side-nav-bar {
    width: 100%;
    height: 100%;
    .nav {
        display: grid;
        grid-template-columns: 15% 85%;
        align-items: baseline;
        border-bottom: 1px solid #ddd;
        padding-top: 24px;
        padding-left: 24px;
        cursor: pointer;
    }
}

@media screen and (max-width: 767px) {
    .side-nav-bar {
        .nav {
            grid-template-columns: 1fr;
            padding-left: 5px;
            p {
                font-size: 10px;
                line-height: normal;
                --bs-gutter-x: unset !important;
                width: 100%;
                max-width: 100%;
                margin-top: 10px;
                text-align: center;
            }

            img {
                position: relative;
                left: 22px;
            }
        }
    }
}
