@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.timer {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
    ul {
        display: flex;
        gap: 5px;
        li {
            width: 60px !important;
            height: 60px !important;
            flex-shrink: 0;
            border: 1px solid #e4e4e4;
            background: rgba(255, 255, 255, 0.8) !important;
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            gap: 5px !important;
            span {
                color: #000;
                text-align: center;
                font-family: Inter;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
