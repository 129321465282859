.owl-carousel-section {
    width: 100% !important;
    max-width: 100% !important;
    padding: 20px 0;
    .owl-stage-outer {
        .item {
            ul {
                width: 100%;
                    display: flex;

                li {
                    text-align: center;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    img{
                        width: 100%;
                        height: 150px;
                        object-fit: cover;
                    }
                    p {
                        margin-top: 15px;
                        color: #2b2857;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .owl-carousel-section .owl-stage-outer .item ul li p {
        margin-top: 15px;
        color: #2b2857;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
    }
}
