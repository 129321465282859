@import '../../style/mixins.scss';
@import '../../style/variable.scss';

.admin-list {
    ul {
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            background: #f8f8f8;
            width: 100%;
            height: auto;
            padding: 10px 20px;
            margin-bottom: 10px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.listing-list {
    ul {
        li {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            background: #f8f8f8;
            width: 100%;
            height: auto;
            padding: 10px 20px;
            margin-bottom: 20px;
        }
    }
}

.lisiting-list-desc {
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 40px;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 60px;
    }
    h4 {
        color: $black;
        font-size: 18px !important;
        font-weight: normal !important;
        margin-bottom: 0px;
        @media screen and (max-width: 580px) {
            font-size: 14px !important;
            line-height: 24px;
        }
        small {
            color: $color-orange;
            font-size: 14px !important;
            font-weight: bold;
            margin-bottom: 0;
            font-style: italic;
        }
        .status{
            font-size: 13px !important;
            padding: 2px 5px;
            color: #666;
            font-weight: bold;
            border: solid 1px #666;
            border-radius: 5px;
            margin-left: 10px;
            background: #fff;
        }
    }

    p {
        margin-bottom: 0;
        height: 33px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 580px) {
            height: 21px;
        }
    }
    .lisiting-content {
        display: grid;
        gap: 10px;
        @media screen and (max-width: 580px) {
            gap: 0px;
        }
        .listing-heading {
            display: flex;
            align-items: center;

            svg {
                height: 1rem;
                width: 3rem;
            }
        }
    }
}

.icons {
    display: flex;
    max-width: 300px;
    gap: 15px;
    justify-content: center;
    svg {
        width: 20px;
    }
}

.btn-gray {
    background: $gray-shade;
    font-weight: 600;
    color: #fff !important;
    &:hover {
        background: $light-black;
    }
}
.btn-orange {
    background: $color-orange;
}

@media screen and (max-width: 1440px) {
    .listingwrap {
        margin: 0 auto;
        max-width: 1400px;
        padding: 0 30px;
    }
}

@media screen and (max-width: 1366px) {
    .listing-list {
        ul {
            li {
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .listingpanel {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 860px) {
    .listing-list {
        ul {
            li {
                gap: 20px;
                padding: 20px 10px;
            }
        }
        .btns {
            max-width: fit-content;
        }
    }

    .lisiting-list-desc {
        gap: 5px;
        width: 100%;
    }

    .admin-list {
        ul {
            li {
                gap: 10px;
                .btns {
                    display: flex;
                    flex-direction: column;
                    max-width: 300px;
                    align-items: center;
                    button {
                        width: 100%;
                    }
                }

                .limbo-list-desc {
                    gap: 20px;
                }
            }
        }

        .list-img {
            width: 100%;
            max-width: 100%;
            img {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .listingpanel {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 640px) {
    .admin-list {
        ul {
            li {
                flex-direction: column;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }

                .limbo-list-desc {
                    grid-template-columns: 1fr;
                    .btns {
                        justify-content: center;
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }


    .listing-list {
        ul {
            li {
                gap: 10px;
                padding: 10px;
            }
        }
        .list-img {
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .btns {
            max-width: none !important;
        }
    }

    .lisiting-list-desc {
        text-align: left;
        grid-template-columns: 1fr;
        h4 {
            padding-bottom: 5px;
        }
        h5 {
            padding: 12px 0;
        }
    }

    .limbo-list {
        ul {
            li {
                gap: 30px;
                padding: 10px;
            }
        }

        .limbo-list-desc {
            grid-template-columns: 1fr;

            h4 {
                padding-bottom: 5px;
            }
            h5 {
                padding: 12px 0;
            }
            .limbo-content {
                display: grid;
                gap: 10px;
                text-align: left;
            }
        }

        .btns {
            max-width: none !important;
        }
    }

    .limbo-list-desc {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 10px;
    }
}

@media screen and (max-width: 580px) {
    .listingpanel {
        grid-template-columns: repeat(1, 1fr);
    }
}
