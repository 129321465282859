.carousel-item {
    img {
        width: 100%;
    }
}

.carousel-caption {
    top: 35%;
    left: 0;
    text-align: left;
    bottom: 35%;
    max-width: 1400px;
    margin: 0 auto;
    right: 0;
    padding: 0 30px;
}

.carousel-control-next,
.carousel-control-prev {
    width: 4%;
}

@media screen and (max-width: 1440px) {
    .carousel-caption {
        padding: 0 80px;
    }
    .carousel-item {
        img {
            height: 330px;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 767px) {
    .carousel-control-next, .carousel-control-prev {
        width: 10%;
    }
    
}



