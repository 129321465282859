@mixin common-width {
    max-width: 1400px;
    margin: 0 auto;
}

@mixin align-center {
    display: flex;
    align-items: center;
}

@mixin space-between {
    justify-content: space-between;
    display: flex;
}

@mixin justify-content {
    justify-content: space-between;
    display: flex;
}


@mixin justify-content-flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@mixin flex-direction-flex-start {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


@mixin align-item-center-flex-direction {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

@mixin align-item-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin button {
    border-radius: 40px;
    padding: 12px 35px;
    border: 0;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    text-transform: none;
}

@mixin center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin alignCenter {    
    display: flex;
    align-items: center;
}
@mixin justifyCenter {
    display: flex;
    justify-content: center;
}