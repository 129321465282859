@import '../../../style/variable.scss';
@import '../../../style/mixins.scss';

.limbo-details-section {
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 30px;
    .details-section {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        h3 {
            color: #000;
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            small {
                font-size: 16px;
                text-transform: none;
                font-style: italic;
                font-weight: normal;
            }
        }
    }

    a {
        color: $theme-blue;
        text-decoration: underline;
    }
}
.vendor-info-section {
    margin-top: 20px;
}

@media screen and (max-width: 1440px) {
    .limbo-details-section .details-section {
        padding: 0 20px;
    }
}

@media screen and (max-width: 767px) {

    .limbo-details-section .details-section {
        display: grid;
        grid-template-columns: 1fr !important;
    }


    .limbo-details-section {
        .details-section {
            display: grid;
            grid-template-columns: 1fr;
        }
    }

    .time-detail {
        .time-details {
            padding-bottom: 20px;
            flex-direction: row;
            gap: 20px;
            align-items: center;
        }
        .limbo-title {
            flex-direction: row;
        }
    }
}
