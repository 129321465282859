@import '../../style/variable.scss';
@import '../../style/mixins.scss';

.current-offer-table-section {
    h3 {
        font-size: 20px;
        border-bottom: 1px solid #dbdbdb;
        margin-bottom: 0px;
        padding-bottom: 20px;
    }
    table {
        &.table {
            tr {
                // display: grid;
                // grid-template-columns: 400px 240px auto;
                // grid-template-columns: repeat(4, 1fr);
                &:hover {
                    background: #f7f7f7 !important;
                }
                td {
                    &:last-child {
                        img {
                            width: 20px;
                        }
                    }
                }
            }

            th {
                padding: 10px 10px;
                color: $light-black;
                font-size: 16px;
                font-weight: 600 !important;
                text-transform: capitalize;
            }
            td {
                padding: 10px;
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        td {
                            background: #f7f7f7 !important;
                        }
                    }
                    td {
                        .actionlink{
                            padding: 0 5px;
                            margin: 0 15px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .current-offer-table {
        padding: 0 20px;
        thead {
            th {
                padding-left: 20px !important;
            }
        }
        tbody {
            td {
                padding-left: 20px !important;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .current-offer-table-section {
        h3 {
            font-size: 18px;
            font-weight: 700 !important;
        }
        table {
            &.table {
                th {
                    font-size: 16px;
                }
            }
        }
    }
}
