@import '../../style/variable.scss';
@import '../../style/mixins.scss';

header {
    width: 100%;
    border-bottom: 1px solid $light-gray;

    .header {
        background: $light-green;

        .head {
            @include common-width;
            margin: 0 auto;
            height: 88px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .search {
                margin-left: 0;

                .form-field {
                    position: relative;
                    display: flex;
                    align-items: center;
                }

                .icon {
                    position: absolute;
                    right: 15px;
                    cursor: pointer;
                }

                input {
                    height: 42px;
                    border: none;
                    border-radius: 40px;
                    width: 377px;
                    padding: 0 0 0 20px;
                    outline: none !important;
                }
            }
        }

        .header-icons {
            @media screen and (max-width: 620px) {
                min-width: unset;
            }

            &:hover {
                cursor: pointer;
            }

            ul {
                display: flex;
                column-gap: 40px;
                padding-left: 75px;
                justify-content: end;
                align-items: center;

                li {
                    display: flex;
                    gap: 15px;
                }
            }

            p {
                margin-bottom: 0 !important;
                font-size: 14px;
                margin-top: 0;
                font-weight: 600 !important;
                text-transform: uppercase;
                color: $black;
            }

            h6 {
                margin-top: 0px !important;
                font-size: 16px;
                margin-bottom: 8px;
                font-weight: 600;
            }

            .notification {
                position: relative;
                padding-bottom: 10px;
                padding-right: 6px;
                min-width: 20px;
                max-width: 35px;
                min-height: 40px;
                max-height: 40px;
                z-index: 2;

                &-dot {
                    position: absolute;
                    border-radius: 99px;
                    border: 2px solid #b6e2c5;
                    background-color: white;
                    color: red;
                    font-weight: bold;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 2px;
                    min-width: 28px;
                    max-width: 28px;
                    min-height: 28px;
                    max-height: 28px;
                    top: -11px;
                    right: 0px;
                    font-size: 10px;
                }

                header {
                    .header {
                        .header-icons {
                            .notification-dot {
                                min-width: 28px;
                                max-width: 28px;
                                min-height: 28px;
                                max-height: 28px;
                                top: -11px;
                                right: 0px;
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    span {
        &.first-letter {
            position: absolute;
            left: -38px;
            font-size: 16px;
            font-weight: 500;
            height: 30px;
            width: 30px;
            border-radius: 30px;
            text-align: center;
            line-height: 30px;
            top: -1px;
            border: 1px solid #4CAF50;
            cursor: default;
            text-transform: uppercase;
            background: #4CAF50;
            color: #fff;
        }
    }
}



.m-show {
    display: none !important;
}

.top-links {
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    background: none;
    margin-right: 0px;

    @media screen and (max-width: 580px) {
        border-radius: 5px;
        width: 100%;
        padding: 0;
    }

    .btn-orange {
        min-height: 40px;
        min-width: 112px;
        font-size: 12px;
        padding: 0 20px;
        border-radius: 0;
    }

    ul {
        display: flex;
        gap: 15px;
        margin: 0;
        padding: 0;
        align-items: center;

        li {
            margin: 0;
            padding: 0;
        }

        a {
            font-weight: 400;
            padding: 10px;

            @media screen and (max-width: 620px) {
                font-weight: normal;
                padding: 5px;
                font-size: 12px;
            }
        }

        span {
            margin-left: 10px;
            font-weight: 400;
            font-size: 14px;
        }
    }
}

li {
    &.location-bar {
        display: flex;
        align-items: center;
        gap: 12px;

        .head-location {
            cursor: pointer;
            p {
                margin-bottom: 2px;
                font-weight: 600 !important;
                color: #000;
                text-transform: uppercase;
            }

            h6 {
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
}

/*media query start*/

@media screen and (max-width: 1440px) {
    header {
        .head {
            padding: 0 30px;
        }
    }
}

@media screen and (max-width: 1170px) {
    header {
        .topbar {
            padding: 0 30px;
        }

        .head {
            padding: 0 30px;

            .logo {
                img {
                    width: 80%;
                }
            }
        }

        .header {
            .header-icons {
                ul {
                    padding-left: 0;
                    column-gap: 20px;
                }

                h6 {
                    font-size: 14px;
                }

                img {
                    width: 20px;
                }

                .notification {
                    &-dot {
                        right: -6px;
                        height: 25px;
                        width: 15px;
                        font-size: 10px;
                        top: -1px;
                    }
                }
            }
        }
    }


}

@media screen and (max-width: 991px) {
    header {
        .head {
            padding: 0 10px;
            display: flex !important;
            gap: 20px;

            .logo {
                img {
                    width: 160px;
                }
            }

            .search {
                input {
                    width: 100% !important;
                }
            }
        }

        .header {
            .header-icons {
                .notification {
                    padding-bottom: 0;
                }
            }
        }
    }

    header {
        .header {
            .head {
                .search {
                    width: 40%;
                }
            }
        }
    }

}

@media screen and (max-width: 767px) {
    header {
        .topbar {
            padding: 0;
            font-size: 14px;

            .top-links {
                ul {
                    align-items: center;
                }
            }
        }

        .head {
            .logo {
                img {
                    width: 160px;
                }
            }
        }
    }

    li.location-bar {
        display: none;
    }

    .mappopup {
        display: none;
    }

    header {
        .header {
            .head {
                .search {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .m-show {
        display: flex !important;
    }

    .m-hide {
        display: none !important;
    }

    header {
        .topbar {
            width: 100%;
        }

        .top-links {
            text-transform: capitalize;
        }

        .head {
            gap: 10px;

            .logo {
                img {
                    width: 140px;
                }
            }
        }

        .header {
            .header-icons {
                img {
                    width: 15px;
                }
            }

            .head {
                .search {
                    input {
                        padding: 0 10px;
                    }
                }
            }

            .btn-orange {
                min-height: 34px;
            }
        }

        .navbar {
            ul {
                gap: 0px;
                font-size: 14px;
                flex-direction: column;
                width: 100%;

                li {
                    width: 100%;
                    align-items: center;
                    text-align: center;

                    img {
                        position: absolute;
                        right: -15px;
                        top: 6px;
                    }
                }

                .btn-orange {
                    min-height: unset;
                    min-width: unset;
                    width: 100%;
                    padding: 5px 0;
                }
            }
        }
    }
}

.header-popover-notification {
    .chatroom-container {
        font-size: 12px;
        color: black;
        font-weight: 600;
        min-width: 300px;
        max-height: 450px;
        overflow-y: scroll;

        .chat-title {
            font-size: 14px;
            font-weight: 500;
            padding: 16px;
            border-bottom: 1px solid black;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .notification-number {
                font-size: 11px;
                font-weight: 300;
            }
        }

        .chat-content {
            border-top: 1px solid green;
            min-width: 80px;
            padding: 8px;

            &:hover {
                cursor: pointer;
            }

            .chat-img-title {
                max-height: 28px;

                .chat-img {
                    min-height: 30px;
                    min-width: 30px;
                }

                .chat-email {
                    font-size: 14px;
                }
            }

            .chat-message {
                margin-left: 2.4rem;
                font-weight: 400;
                font-size: 14px;
                padding-top: 0.35rem;
            }

            .chat-timestamp {
                margin-left: 2.4rem;
                font-weight: 300;
                font-size: 11px;
                padding-top: 0.35rem;
            }
        }

        .show-all-notification-button {
            border-top: 1px solid black;
            padding: 1rem;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: red;
            opacity: 0.8;

            &:hover {
                cursor: pointer;
                color: red;
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .head {
        .search {
            input {
                padding: 0 10px;

                &::-webkit-input-placeholder {
                    width: 80% !important;
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    overflow: hidden !important;
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .navbar .container {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        width: 100% !important;
    }
}